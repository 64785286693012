<template>
  <div class="preview">
    <van-nav-bar
      v-if="!$root.isInMini"
      title="附件简历预览"
      class="header"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="filed-box" id="preview">
      <van-field
        v-if="query.deliveryStateCode"
        v-model="query.stateDesc"
        name="当前简历状态"
        label="当前简历状态"
        required
        readonly
        placeholder
        :rules="[{ required: true }]"
        @click="resumeShow"
      >
        <template #button>
          <van-icon v-if="resumeStatusPicker" name="arrow-up" />
          <van-icon v-else name="arrow-down" />
        </template>
      </van-field>
      <van-popup v-model="resumeStatusPicker" round position="bottom">
        <van-picker
          show-toolbar
          :columns="deliveryStateList"
          @cancel="resumeStatusPicker = false"
          @confirm="resumeStatusConfirm"
        >
          <template #option="item">
            <p style="text-align: center">{{ item.name }}</p>
          </template>
        </van-picker>
      </van-popup>
    </div>
    <div class="btn-box" v-if="!$root.isInMini">
      <van-button round block type="info" @click="download"
        >下载原始简历</van-button
      >
    </div>
    <div class="btn-wrap"></div>
    <iframe :src="previewUrl" frameborder="0" class="iframe"></iframe>
  </div>
</template>

<script>
  export default {
    name: 'preview',
    data() {
      return {
        previewUrl: '',
        resumeStatusPicker: false,
        deliveryStateList: [],
        deliveryStateCode: '', //当前处理状态值
        stateDesc: '', //当前处理流程名称
        query: {},
      };
    },
    created() {
      this.previewUrl = decodeURIComponent(this.$route.query.previewUrl);
    },
    async mounted() {
      let { query } = this.$route;
      this.query = query;
    },
    methods: {
      onClickLeft() {
        this.$router.go(-1);
      },
      download() {
        window.open(decodeURIComponent(this.$route.query.url), '_blank');
      },
      resumeShow() {
        if (this.query.deliveryStateCode) {
          this.deliveryStateList = this.options(this.query.deliveryStateCode);
          if (this.deliveryStateList.length === 0) {
            this.$toast({
              message: '当前状态不能处理',
              getContainer: '#preview',
            });
            return;
          }
          this.resumeStatusPicker = true;
        }
      },
      options(deliveryStateCode) {
        var arr = [];
        if (deliveryStateCode == 'WAITING') {
          arr = [
            { code: 'PLATFORMFILTERPASS', name: '企业评估中' },
            { code: 'COMPANYFILTERFPASS', name: '企业筛选通过·可约面试' },
            { code: 'COMPANYFILTERFAIL', name: '企业筛选未通过' },
          ];
        }
        if (deliveryStateCode == 'PLATFORMFILTERPASS') {
          arr = [
            { code: 'COMPANYFILTERFPASS', name: '企业筛选通过.可约面试' },
            { code: 'COMPANYFILTERFAIL', name: '企业筛选未通过' },
          ];
        }
        if (deliveryStateCode == 'COMPANYFILTERFPASS') {
          arr = [
            { code: 'INTERVIEWPASS', name: '面试通过' },
            { code: 'INTERVIEWFAIL', name: '面试未通过' },
          ];
        }
        if (deliveryStateCode == 'INTERVIEWPASS') {
          arr = [
            { code: 'EMPLOYED', name: '已入职' },
            { code: 'GIVE_UP_ENTRY', name: '放弃入职' },
          ];
        }
        return arr;
      },
      resumeStatusConfirm(data) {
        if (!this.query.id) return;
        this.resumeStatusPicker = false;
        this.$dialog
          .confirm({
            message: `确认要${data.name}么?`,
            getContainer: '#preview',
          })
          .then(() => {
            this.$http
              .put(
                `/selfhelpOfficialAccounts/deliveryRecord/${this.query.id}/${data.code}`
              )
              .then(async (res) => {
                if (res.code === 200) {
                  this.query.deliveryStateCode = data.code;
                  this.query.stateDesc = data.name;
                  this.$toast({
                    message: '处理成功',
                    getContainer: '#preview',
                  });
                  this.$forceUpdate();
                } else {
                  this.$toast({
                    message: res.message || '处理失败',
                    getContainer: '#preview',
                  });
                }
              });
          });
      },
    },
  };
</script>

<style scoped lang="scss">
  .preview {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    z-index: 99999;
  }

  .filed-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ebedf0;
    > .van-cell {
      padding-right: 16px;
      padding-left: 16px;
      flex: 1;
    }
    > .van-cell::after {
      border-bottom: none;
    }
    ::v-deep .van-field__label {
      width: auto;
    }
    ::v-deep .van-field__label span {
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }

    ::v-deep .van-field--disabled .van-field__label {
      color: #646566;
    }
    ::v-deep .van-field__value input {
      text-align: right;
    }
    ::v-deep .van-field__control--custom {
      justify-content: right;
    }
    ::v-deep .van-field__control {
      text-align: right;
    }
  }
  .btn-box {
    margin: 5px 0;
    padding: 0 46px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 10%;
    left: 0;
    right: 0;
  }
  .iframe {
    flex: 1;
  }
</style>
